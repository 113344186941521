import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

// import app components
import { Spacer, Edges, Textarea, PageIntro } from "components"

export default function FaqTemplate(props) {
  const {
    data: {
      page: {
        templateFaq: {
          faqFields: {
            faqHero: { image, headline, text },
            faqContent: { main },
            faqQa
          }
        }
      }
    }
  } = props

  return (
    <>
      <PageIntro headline={headline} image={image} text={text} />

      <ContentContainer>
        <Edges size="cont">
          <Grid container>
            <Grid item md={2} />
            <Grid item md={8}>
              <Spacer mt={80} />
              {main && (
                <Spacer mb={60}>
                  <Textarea content={main} />
                </Spacer>
              )}
              {faqQa &&
                faqQa.map((o, i) => {
                  return (
                    <Spacer key={i} mb={50}>
                      {o.faqTitle && (
                        <Spacer mb={30}>
                          <Typography children={o.faqTitle} variant="h2" />
                        </Spacer>
                      )}

                      {o.qAndA &&
                        o.qAndA.map((p, j) => {
                          return (
                            <StyledAccordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel_${i}_${j}a-content`}
                                id={`panel_${i}_${j}-header`}
                              >
                                <Typography>{p.question}</Typography>
                              </AccordionSummary>

                              <StyledAccordionDetails>
                                <Typography>
                                  <Textarea content={p.answer} />
                                </Typography>
                              </StyledAccordionDetails>
                            </StyledAccordion>
                          )
                        })}
                    </Spacer>
                  )
                })}
            </Grid>
          </Grid>
          <Spacer pb={60} />
        </Edges>
      </ContentContainer>
    </>
  )
}

const ContentContainer = styled.div``

const StyledAccordion = styled(Accordion)`
  &:before,
  &:after {
    background-color: #252525;
  }

  &.Mui-expanded {
    &:before {
      opacity: 1;
    }
  }

  &:last-child {
    &:after {
      left: 0;
      right: 0;
      height: 1px;
      content: "";
      opacity: 1;
      position: absolute;
      transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  .MuiAccordionSummary-root {
    padding: 0px;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding-left: 50px;

  @media (min-width: 768px) {
    padding-left: 100px;
  }
`

export const CollectionQuery = graphql`query FaqTemplate($id: String!) {
  page: wpPage(id: {eq: $id}) {
    templateFaq {
      faqFields {
        faqHero {
          text
          headline
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
        faqContent {
          main
        }
        faqQa {
          faqTitle
          qAndA {
            question
            answer
          }
        }
      }
    }
  }
}
`
